<template>
  <v-container>
    <v-card outlined rounded-pill max-width="700px" class="mx-auto pa-1" color="transparent">
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 160px;"
          id="company" 
          ref="company" 
          label="경영체✽" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;" 
          id="farm" 
          ref="farm" 
          label="농장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 170px;"
          id="building" 
          ref="building" 
          label="축사✽" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 80px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <v-card :loading="loading" nowrap outlined class="table" > 
      <div id="seasonTable" >
        <div class="season_header" style="display:flex">
          <div style="display: flex;"> 
            <div class="table_title"> 
              <v-dialog
                  v-model="dialogWeight"
                  max-width="400" 
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div  
                      class="weight_btn elevation-3 mt-5 ml-1" v-show="edit_mode"
                      v-bind="attrs"
                      v-on="on"
                      @click="newWeight">
                      <v-icon
                        color=white
                        size="10"
                      >
                        mdi-plus
                      </v-icon>
                      <span class="mr-1"> 체중 </span>
                    </div>
                  </template>
                <WeightSet class="pa-0"
                  :setData="setData" 
                  @close="dialogWeight = false"
                  @refresh="refresh"
                  @checkDuplicate="weightDuplicate"
                />
              </v-dialog>
              <span v-show="edit_mode" class="ml-1 mr-1"> \ </span>
              <v-dialog
                v-model="dialogSeason"
                max-width="350" 
              >
                <template v-slot:activator="{ on, attrs }">            
                  <div outlined class="weight_btn elevation-3 mb-2" 
                    v-show="edit_mode  && header_weight.length"
                    v-bind="attrs"
                    v-on="on" 
                    @click="newSeason">
                    <v-icon
                    color=white
                    size="10"
                    >
                      mdi-plus
                    </v-icon>
                    <span class="mr-1">계절</span>
                  </div>
                </template>
                  <SeasonSet class="pa-0"
                    :season_setData="season_setData"
                    @close="dialogSeason = false"
                    @refresh="refresh"
                    @checkDuplicate="seasonDuplicate"
                  />
              </v-dialog>

              <div v-show="!edit_mode" class="mt-1">
                <span> 체중 \ 계절</span>
              </div>
            </div>
            <div class="season_section" tabindex="-1" :class="{ cursorpointer: edit_mode, set_click:edit_mode }" v-for="(header_season,hs) in header_season" :key="hs" @click="updateSeasonRange(header_season)"> 
              <div style="font-size:16px;"> {{header_season.season_name}} </div>
              <div class="header_date">{{ dateMD(header_season.season_from) }}-{{dateMD(header_season.season_to)}}</div>
            </div>
        </div>
        </div>
      
      <div>
        <div style="display:flex">
          <div class="weight_header">
            <div>
              <div class="weight_section" tabindex="-1" :class="{ cursorpointer: edit_mode, set_click:edit_mode }"
                  v-for="(header_weight,hw) in header_weight" :key="hw" 
                  @click="updateWeightRange(header_weight)"> 
                  
                <div style="font-size:16px"> {{header_weight.weight_name}} </div>
                <div class="weight_des"> {{header_weight.weight_min}} - {{header_weight.weight_max}} kg </div>
                <div class="weight_des" v-if="header_weight.age_min"> {{header_weight.age_min}} - {{header_weight.age_max}}주 </div>
              </div>

            </div>
        </div>

        <div style="display:flex">
          <div class="controlContents">
              <div v-for="(weight,w) in weight_list" :key="w">
                <v-layout>
                  <div v-for="(season,s) in weight.season_list" :key="s">
                    <div class="setting" tabindex="-1" v-if="season.season_id" :class="{cursorpointer:edit_mode, set_click:edit_mode}" @click="updateControlRange(weight, season)"> 
                    <div class="fc_info" v-if="season.fan" >
      
                        <div class="fc_content"> 
                          <div class="mr-1 fc_number"> 1 </div>
                          <div class="fc_vent">
                            {{season.fan.min_vent1}} - {{season.fan.max_vent1}} 
                          </div> 
                          <span class="ml-1 mr-1"></span> 
                          {{season.fan.set_temp1}} 
                          <span class="ml-1 mr-1"> / </span> 
                          {{ season.fan.dev_temp1 }}
                        </div>

                        <div class="fc_content"> 
                          <div class="mr-1 fc_number"> 2 </div>
                          <div class="fc_vent">
                            {{season.fan.min_vent2}} - {{season.fan.max_vent2}} 
                          </div> 
                          <span class="ml-1 mr-1"></span> 
                          {{season.fan.set_temp2}} 
                          <span class="ml-1 mr-1"> / </span> 
                          {{ season.fan.dev_temp2 }}
                        </div>

                              
                        <div class="fc_content"> 
                          <div class="mr-1 fc_number"> 3 </div>
                          <div class="fc_vent">
                            {{season.fan.min_vent3}} - {{season.fan.max_vent3}} 
                          </div> 
                          <span class="ml-1 mr-1"></span> 
                          {{season.fan.set_temp3}} 
                          <span class="ml-1 mr-1"> / </span> 
                          {{ season.fan.dev_temp3 }}
                        </div>

                        </div>
                  
                    <div class="heater_info" v-if="season.heater">
                      <div>
                        {{ season.heater.on_off1 ?  `${season.heater.min_vent1} - ${season.heater.max_vent1} / ${season.heater.set_temp1}` : "OFF" }} </div> 
                    </div>
                  </div>
                  </div>     
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--컨트롤러 세팅 pop-up-->
      <v-dialog
        v-model="dialogSet"
        max-width="500"
      >
        <ControllerSet class="pa-0"
        :control_setData="control_setData"
        @close="dialogSet = false"
        @refresh="refresh"/>
      </v-dialog>

      <v-row class="justify-center mb-1">
        <v-btn v-show="editBtnShow" v-if="$getters.userInfo.grade < 'UT06'" outlined rounded class="modify_btn elevation-3" 
          @click="edit_mode = !edit_mode; $store.commit('resMessage',''); presetLoadShow=edit_mode; presetSaveShow=!edit_mode;"> 
          {{edit_mode ? "나가기" : "수정하기"}}
        </v-btn>

        <v-btn v-show="presetCanceShow" @click="refresh();" outlined rounded class="modify_btn elevation-3 cancel lightgreen ml-2">
              되돌리기
        </v-btn>

 
        <template>
          <div class="text-center">
            <v-btn v-show="presetLoadShow" @click="loadDialog = true; presetCompany={}; preset='';" outlined rounded class="modify_btn elevation-3 lightgreen ml-2">
              프리셋 불러오기
            </v-btn>

            <v-dialog v-model="loadDialog" width="350">
              <v-card max-width="350" class="pa-5">
                <span class="dialog_title">프리셋 불러오기</span>
                <v-row class="ma-3">
                  <div class="preset_title brandcolor">경영체</div>
                  <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
                    id="presetCompany" 
                    ref="presetCompany" 
                    v-model="presetCompany"
                    :items="presetCompanyItems"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    item-text="name"
                    return-object
                    @focus="comboPresetCompanies()"
                    clearable
                    />
                </v-row>

                <v-row class="ma-3">
                  <div class="preset_title brandcolor">프리셋</div>
                  <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
                    id="preset" 
                    ref="preset" 
                    v-model="preset"
                    :items="presetItems"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    return-object
                    @focus="comboPresets()"
                    clearable
                    />
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="loadDialog = false" class="apply_btn brandcolor">닫기</v-btn>
                  <v-btn @click="presetLoad()" class="apply_btn brandback">불러오기</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template>
          <div class="text-center">
            <v-btn v-show="presetSaveShow" outlined rounded class="modify_btn elevation-3 sblue ml-2 mr-2" 
              @click="saveDialog = true; presetCompany={}; presetNm='';"> 
              프리셋으로 저장
            </v-btn>
            <v-dialog v-model="saveDialog" width="350">
              <v-card max-width="350" class="pa-5">
                <span class="dialog_title">프리셋으로 저장</span>
                <v-row class="ma-3">
                  <div class="preset_title brandcolor">경영체</div>
                  <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 140px;"
                    id="presetCompany" 
                    ref="presetCompany" 
                    v-model="presetCompany"
                    :items="presetCompanyItems"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    item-text="name"
                    return-object
                    @focus="comboPresetCompanies()"
                    clearable
                    />
                </v-row>

                <v-row class="ma-3">
                  <div class="preset_title brandcolor mr-1">프리셋명</div>
                  <v-text-field dense outlined rounded clearable class="shink" 
                    style="height: 40px; width: 150px;"
                    id="presetNm" 
                    ref="presetNm" 
                    v-model="presetNm"
                    color="#3a4f3f"
                  ></v-text-field>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="saveDialog = false" class="apply_btn brandcolor">취소</v-btn>
                  <v-btn @click="presetSave()" class="apply_btn brandback">확인</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template>
          <div class="text-center">
            <v-btn v-show="presetConfirmShow" outlined rounded class="modify_btn elevation-3 ml-2 sky" 
              @click="presetConfirm()"> 
              적용하기
            </v-btn>
          </div>
        </template>
      </v-row>

    </v-card>

  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js"; 

import WeightSet from './child/WeightSet.vue';
import SeasonSet from './child/SeasonSet.vue';
import ControllerSet from './child/ControllerSet.vue';
const year = dateUtil.format(new Date(), "yyyy-");

export default {
  name: "ControlTable2",

  components: {
    WeightSet,
    SeasonSet,
    ControllerSet
  },

  async created() {
    Apis.pageCount({
        page_name: this.$route.name,
        url: this.$route.path,
        division: "pigro",
    }, () => {
    }).catch((err) => {
        console.log("pageCount 호출 오류", err);
    });
    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();
    
    let cookie_info = VueCookies.get("controltable_info"); // 이전 조회정보를 참조
    if (!this.isNull(this.$route.params)) {
    // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      if ( this.$route.params.building && this.$route.params.building.code) {
        this.building = this.$route.params.building
      } else { 
          await this.comboBuildings();
          this.building = this.buildingItems[0];
      }
    } else if (cookie_info) {
          this.company = cookie_info.company || {};
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
    } else { // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
          this.building = this.buildingItems[0];
          this.company = { code: this.building.company_cd, name: this.building.company_name };
          this.farm = { code: this.building.farm_cd, name: this.building.farm_name };
    }
    this.refreshList();
  },

  data() {
    return {
      icon_fangreen: require("@/assets/monitoring/fan_control_green.svg"),


      company: {},
      companyItems: [],
      farm: {},
      farmItems: [],
      building: {},
      buildingItems: [],
      loading: false,
      weight_list: [],
      season_list: [],
      header_season: [],
      header_weight: [],
      edit_mode: false,
      combobox: true,
      data_text: "",

      dialogWeight: false,
      dialogSeason: false,
      dialogSet: false,

      setData: {},
      season_setData: {},
      control_setData: {},
      msgFlag: true,

      loadDialog:false,
      saveDialog:false,

      presetCompany:{},
      presetCompanyItems:[],
      preset:'',
      presetItems:[],
      presetNm:'',

      editBtnShow: true,
      presetLoadShow: false,
      presetSaveShow: true,
      presetConfirmShow: false,
      presetCanceShow: false,
    };
  },
  methods: {
    newSeason(){
      this.$store.commit("resMessage","");
      this.season_setData = {company_cd: this.company.code,
                            farm_cd: this.farm.code,
                            building_cd: this.building.code,
                            building_name: this.building.name,
                            season_name: "",
                            season_from: "",
                            season_to: "",
                            description:"",
                            history:"",
                            season_id:0 };
    },
    updateSeasonRange(item) {
      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.season_setData = {company_cd: this.company.code,
                            farm_cd: this.farm.code,
                            building_cd: this.building.code,
                            building_name: this.building.name,
                            season_name: item.season_name,
                            season_from: dateUtil.format(new Date(), "yyyy-")+item.season_from,
                            season_to: dateUtil.format(new Date(), "yyyy-")+item.season_to,
                            season_id: item.id,
                            description: item.descripton,
                            history: item.history,
                          };
      this.dialogSeason = true;
    },
    newWeight() {
      this.$store.commit("resMessage","");
      this.setData = {company_cd: this.company.code,
                      farm_cd: this.farm.code,
                      building_cd: this.building.code,
                      building_name: this.building.name,
                      weight_name: "",
                      weight_min: 0,
                      age_min: 0,
                      age_max: 0,
                      weight_max: 0,
                      description: "",
                      weight_id: 0,
                      history: "",
                      };
    },
    updateWeightRange(item) {
      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.setData = {company_cd: this.company.code,
                      farm_cd: this.farm.code,
                      building_cd: this.building.code,
                      building_name: this.building.name,
                      weight_id: item.id,
                      weight_name: item.weight_name,
                      weight_min: item.weight_min,
                      weight_max: item.weight_max,
                      age_min: item.age_min,
                      age_max: item.age_max,
                      description: item.descripton,
                      history: item.history,
                    };
      this.dialogWeight = true;
    },

    updateControlRange(weight, season){
      // console.log(weight, season);

      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.control_setData = {company_cd: this.company.code,
                              farm_cd: this.farm.code,
                              building_cd: this.building.code,
                              building_name: this.building.name,

                              season_id: season.season_id,
                              season_name: season.season_name,
                              weight_id: weight.weight_id,
                              weight_name: weight.weight_name,

                              fan: {on_off1 : true,
                              on_off2: true,
                              on_off3: true,
                              set_temp1: season.fan && season.fan.set_temp1 || 0, 
                              dev_temp1: season.fan && season.fan.dev_temp1 || 0, 
                              max_vent1: season.fan && season.fan.max_vent1 || 0, 
                              min_vent1: season.fan && season.fan.min_vent1 || 0 ,
                              set_temp2: season.fan && season.fan.set_temp2 || 0, 
                              dev_temp2: season.fan && season.fan.dev_temp2 || 0, 
                              max_vent2: season.fan && season.fan.max_vent2 || 0, 
                              min_vent2: season.fan && season.fan.min_vent2 || 0,
                              set_temp3: season.fan && season.fan.set_temp3 || 0, 
                              dev_temp3: season.fan && season.fan.dev_temp3 || 0, 
                              max_vent3: season.fan && season.fan.max_vent3 || 0, 
                              min_vent3: season.fan && season.fan.min_vent3 || 0},

                              heater:{ set_temp1: season.heater && season.heater.set_temp1 || 0, 
                              dev_temp1: season.heater && season.heater.dev_temp1|| 0, 
                              max_vent1: season.heater && season.heater.max_vent1 || 0, 
                              min_vent1: season.heater && season.heater.min_vent1 || 0,
                              on_off1: season.heater && season.heater.on_off1 || false,}
                              };
      this.dialogSet = true;
    },

    dateMD(md) {
        let m = md.slice(0, 2);
        let d = md.slice(3);
        m = (m.slice(0, 1) == "0") ? m.slice(1) : m;
        d = (d.slice(0, 1) == "0") ? d.slice(1) : d;
        return m + "/" + d;
    },
    refresh() {
      this.dialogWeight = false;
      this.dialogSeason = false;
      this.dialogSet = false;

      this.presetConfirmShow=false;
      this.presetSaveShow=true;
      this.editBtnShow=true;

      this.presetLoadShow = false,
      this.presetCanceShow = false,


      this.msgFlag = false;
      this.refreshList();
    },

    refreshList() {
      if (this.msgFlag) this.$store.commit("resMessage","");
      if (!this.building || !this.building.code) {
          alert("축사/건물/구역를 선택하세요");
          this.$refs.building.focus();
          return;
      }
      this.loading = true;
      Apis.listControlRangeSettings({
          building_cd: this.building && this.building.code || "",
      }, (res) => {
          this.header_weight = [];
          this.header_season = [];
          this.weight_list = res.data;
          this.weight_list.forEach(item => {
              this.header_weight.push({ id: item.weight_id, weight_name: item.weight_name,
                                        weight_min: item.weight_min, weight_max: item.weight_max,
                                        age_min: item.age_min, age_max: item.age_max,
                                        descripton: item.w_description, history: `${item.w_reguser} / ${item.w_regdate} 등록` });
          });
          if (this.weight_list[0] && this.weight_list[0].season_list[0].season_id) {
           for (let i in this.weight_list[0].season_list) {
              this.header_season.push(
                { id: this.weight_list[0].season_list[i].season_id, season_name: this.weight_list[0].season_list[i].season_name, 
                  season_from: this.weight_list[0].season_list[i].season_from, season_to: this.makeSeasonTo(i),
                  descripton: this.weight_list[0].season_list[i].s_description,
                  history: `${this.weight_list[0].season_list[i].s_reguser} / ${this.weight_list[0].season_list[i].s_regdate} 등록`  }
              );
            }
          }
          if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.loading = false;
          this.msgFlag = true;
          let cookie_info = { company: this.company, farm: this.farm, building: this.building };
          VueCookies.set("controltable_info", cookie_info, "30d");
      }).catch((err) => {
          console.log("listControlRangeSettings 호출 오류", err);
          this.loading = false;
      });
    },
    weightDuplicate(id,name,w_min,a_min,callback) {
      let res = "";
      for (let i in this.header_weight) {
        if (this.header_weight[i].id !== id) {
          if (this.header_weight[i].weight_name == name) {res = "구간명이 중복됩니다"; break;}
          if (this.header_weight[i].weight_min == w_min) {res = "체중하한이 중복됩니다"; break;}
          if (this.header_weight[i].age_min == a_min) {res = "주령하한이 중복됩니다"; break;}
        }
      }
      return callback(res);
    },
    seasonDuplicate(id,s_from,callback) {
      let res = "";
      for (let i in this.header_season) {
        if (this.header_season[i].id !== id) {
          if (this.header_season[i].season_from == s_from) {res = "계절 시작일이 중복됩니다"; break;}
        }
      }
      return callback(res);
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {}; 
      this.building = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },    
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.building = {}; this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
        this.refreshList();
      }
    },
    makeSeasonTo(index) {      // 다음 시즌 시작일의 하루 전날로 까지날짜 세팅
      let n_from = year + this.weight_list[0].season_list[0].season_from;
      if (index < this.weight_list[0].season_list.length - 1 ) {
        n_from =  year + this.weight_list[0].season_list[Number(index) + 1].season_from;
      } 
      return dateUtil.format(dateUtil.addDays(new Date(n_from), -1),"MM-dd"); 
    },

    async comboPresetCompanies(){
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.presetCompanyItems = [{code: "ALL", name: "전 경영체 공용"}];
            for (let i in res.data) {
            this.presetCompanyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboPresetCompanies API 호출 오류",err)
        }
      ) 
    },
    async comboPresets(){
      await Apis.comboFanControlPresets({
        company_cd: this.presetCompany && this.presetCompany.code || "",
        },(res) => {  
          if (res.result) {
            this.presetItems = res.data
            }  
          else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFanControlPresets API 호출 오류",err)
        }
      ) 
    },
    presetLoad(){
      if (!this.presetCompany || !this.presetCompany.code) {
          alert("프리셋 공유 경영체를 선택하세요");
          this.$refs.presetCompany.focus();
          return;
      }
      if (this.preset == "") {
          alert("불러올 프리셋을 선택하세요");
          this.$refs.preset.focus();
          return;
      }
      this.loading = true;
      Apis.loadFanControlPreset({
          company_cd: this.presetCompany.code,
          preset_name: this.preset,
      }, (res) => {
          if (res.result) {
            this.weight_list = res.data;
            this.header_weight = [];
            this.header_season = [];
            this.weight_list.forEach(item => {
              this.header_weight.push({ id: item.weight_id, weight_name: item.weight_name,
                                        weight_min: item.weight_min, weight_max: item.weight_max,
                                        age_min: item.age_min, age_max: item.age_max,
                                        descripton: item.w_description, history: `${item.w_reguser} / ${item.w_regdate} 등록` });
            });
            if (this.weight_list[0] && this.weight_list[0].season_list[0].season_id) {
            for (let i in this.weight_list[0].season_list) {
                this.header_season.push(
                  { id: this.weight_list[0].season_list[i].season_id, season_name: this.weight_list[0].season_list[i].season_name, 
                    season_from: this.weight_list[0].season_list[i].season_from, season_to: this.makeSeasonTo(i),
                    descripton: this.weight_list[0].season_list[i].s_description,
                    history: `${this.weight_list[0].season_list[i].s_reguser} / ${this.weight_list[0].season_list[i].s_regdate} 등록`  }
                );
              }
            }
            this.$store.commit("resMessage",res.message + "적용후 수정할수 있습니다");
            this.loadDialog = false;
            this.edit_mode = false;
            this.editBtnShow = false;
            this.presetSaveShow = false;
            this.presetConfirmShow = true;
            this.presetCanceShow = true;
          } else {
            alert(res.message);
          }
          this.loading = false;
      }).catch((err) => {
          console.log("saveFanControlPreset 호출 오류", err);
          this.loading = false;
      });

    },
    presetSave(){
      if (!this.presetCompany || !this.presetCompany.code) {
          alert("프리셋 공유 경영체를 선택하세요");
          this.$refs.presetCompany.focus();
          return;
      }
      if (this.presetNm.trim() == "") {
          alert("프리셋 이름을 입력하세요");
          this.$refs.presetNm.focus();
          return;
      }
      this.loading = true;
      Apis.saveFanControlPreset({
          company_cd: this.presetCompany.code,
          preset_name: this.presetNm.trim(),
          range_set: this.weight_list,
      }, (res) => {
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.saveDialog = false;
          } else if (res.resultCode == "E300") {
            alert(res.message);
          } else {
            alert(res.message);
          }
          this.loading = false;
      }).catch((err) => {
          console.log("saveFanControlPreset 호출 오류", err);
          this.loading = false;
      });
    },
    presetConfirm(){
      if (!this.building || !this.building.code) {
          alert("축사/건물/구역를 선택하세요");
          this.$refs.building.focus();
          return;
      }
      if (!confirm("주요 확인!\n" + this.building.farm_name + " " +  this.building.name + "의 세팅을 새로 설정합니다, \n 적용후에는 기존 설정을 복구할수 없습니다!!\n 불러온 프리셋을 적용하시겠습니까?")) {
        return
      }
      this.loading = true;
      Apis.confirmFanControlPreset({
          building: {
                      company_cd: this.building.company_cd,
                      farm_cd: this.building.farm_cd,
                      building_cd: this.building.code,
                    },
          list_set: this.weight_list,
      }, (res) => {
          if (res.result) {
            this.saveDialog = false;
            this.refresh();
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
          }
          this.loading = false;
      }).catch((err) => {
          console.log("confirmFanControlPreset 호출 오류", err);
          this.loading = false;
      });
    }
  },
}
</script>


<style lang="scss" scoped >
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Open+Sans:wght@400;600;700&display=swap');
* {
  font-family: "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size:16px;
  -webkit-text-size-adjust: none;
  /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust: none;
  /* IE */
  -moz-text-size-adjust: none;
  /* 파이어폭스 */
  -o-text-size-adjust: none;
  /* 오페라 구버전 */
}

.table{
  text-align: center;
}

#seasonTable{
  text-align:center;
  display: inline-block;
}
.season_header{
  display:flex;
  position:relative;
  // margin-top:3px;
}

.weight_header{
  float:left;
  margin-right: 4px;
  font-size:15px;
  letter-spacing: -1px;
  width:145px;
}

.controlContents{
  position: absolute;
  float:left;
  overflow:hidden;
  flex-wrap: wrap;
  margin-left:-34px;
}

.header_date, .weight_des{
  font-size:14px;
  letter-spacing: -1.25px;
  font-weight: normal !important;
}

.table_title{
  width:115px !important;
  height:50px;
  line-height:50px !important;
  display:flex;
  justify-content: center;
  align-items:center;
  padding:10px;
  display:flex;
  font-weight: bold !important;
  color:grey !important;
}

.season_section{
  width:110px;
  height:50px;
  margin:3px 5px 0;
  border-radius: 5px;
  padding:10px !important;
  line-height:17px;
  font-weight: bold !important;
  background-color: #e8e8e8 !important;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.season_btn{
  border-radius:5px;
  margin:4px 4px 5px 4px!important;
  border-color:transparent;
  background-color: #3a4f3f !important;
  color:white !important;
  font-weight: bold !important;
}

.weight_section{
  // display:flex;
  width:100px;
  height:95px;
  margin:5px 10px;
  margin-bottom:10px;
  font-weight:bold !important;
  line-height:17px !important;
  padding: 20px 15px !important;
  border-radius: 5px;
  background-color: #e8e8e8 !important;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
}
.cursorpointer{
  cursor: pointer;
  box-shadow: 2px 3px 4px 0px #bebebe !important;
}

.set_click:focus{
  border:2px solid #618269 !important;
  box-shadow: 2px 3px 4px 0px #3a4f3f;
  border-radius: 5px;
}

.setting{
  width: 110px;
  height: 95px;
  margin: 5px;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.fc_info{
  // display:flex;
  padding:2px;
  border: solid 1px grey !important;
  border-radius: 5px;
  font-size:13px;
  line-height:20px; 
  letter-spacing: -1.5px !important;
}
.fc_content{
  display:flex; 
  justify-content: center;
  margin-top:1.5px;
}
.fc_vent{
  width:30px;
}
.fc_number{
  background-color:#9EB0A2;
  padding:0 3px 0 2px;
  height:18px;
  text-align:center;
  font-size:10px;
  color:white;
  border-radius: 10px;
}
.heater_info{
  padding:1px;
  border: solid 1px grey !important;
  border-radius: 5px;
  font-size:13px;
  line-height:20px;
  letter-spacing: -1px !important;
}

.set_info{
  width: 40px;
  height: 100px;
  text-align:center;
  margin:4px 1px 9px;
  padding-bottom:0px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
  text-align: center;

}

.fan{
  padding-top:25px;
  line-height:70px;
  height:70px;
  margin-bottom:2px;
  background-color: #e8e8e8 !important;
  border-radius: 5px;
  font-size:10px;
  line-height:20px; 
  letter-spacing: -1px !important;
}

.heater{
  padding:5px;
  height:27px;
  background-color: #e8e8e8 !important;
  border-radius: 5px;
  font-size:10px;
  line-height:20px;
  letter-spacing: -1px !important;
}

.normal-btn{
  background-color: #3a4f3f!important;
  color:white !important;
  font-weight: bold !important;
}
.modify_btn{
  background-color: #3a4f3f!important;
  color:white !important;
  font-weight: bold !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  letter-spacing: -0.5px;
  border-color: transparent;
  height:40px !important; 
  width:120px !important; 
  font-size:15px !important;
}

.weight_btn{
  height:30px !important; 
  width:40px !important;
  line-height:30px;
  border-radius: 10px;
  font-size:12px;
  letter-spacing: -1.25px;
  border-color:transparent;
  color:#3a4f3f !important;
  background-color: #3a4f3f !important;
  color:white !important;
  cursor: pointer;
}
.weight_btn:hover{
  background-color: #9EB0A2 !important;
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  text-align: left;
  letter-spacing: -1.2px;
}
.dialog_title{
  font-weight: bold !important;
  margin-top:10px;
}
.preset_title{
  margin-top:10px;
  width:60px; 
}
.apply_btn{
  border-radius: 30px !important;
  color:white !important;
  font-weight: bold !important;
  letter-spacing: -0.5px;
  border-color: transparent;
  height: 40px !important;
}

.brandback{
  background: #3a4f3f !important;
  color:white !important;
}
.brandcolor{
  letter-spacing: -1px;
  font-weight:600;
  color:#3a4f3f !important;
}
.icon_fan{
  width:15px;
}
.lightgreen{
  background-color:#9EB0A2 !important;
}
.sblue{
  background-color:#304E5C !important;
}
.cancel{
  background-color:#575858 !important;
}
.sky{
  background-color:#99AEBA !important;
}




</style>



